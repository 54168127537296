import React from "react";


const Form = (props) => {


  return (
    <div className="">
      <div class="elfsight-app-3c9e4f66-b3b9-40b8-8c61-2c4c43cbd7f3" data-elfsight-app-lazy></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
